import { Navigation } from './react_navigation'
import ReactDOM from 'react-dom'
import React, { useState, useEffect, useCallback } from 'react'

document.addEventListener('turbolinks:load', () => {
  const node = document.getElementById('navigation')
  
  ReactDOM.render(
    <Navigation email={node.getAttribute('user')}/>, 
    node.appendChild(document.createElement('div'))
  )
})