import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Cookies from 'js-cookie'

let deferredPrompt;
let buttonInstall;

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});

function needsToSeePromptIOS() {
  return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) && !navigator.userAgent.match('CriOS') && !isRunningStandalone()
}

function isRunningStandalone() {
  return (window.matchMedia('(display-mode: standalone)').matches);
}

function showPwaInstallPopupIOS() {
  Swal.fire({
    imageUrl: '/icons/apple-touch-icon-192x192.png',
    imageHeight: 100,
    title: 'Install the App',
    html:
    'Install this application on your home ' +
    'screen for quick and easy access ' +
    'when you are on the go' + 
    '<br/><br/>' +
    'Just tap <img src="/images/safari-install.png" class="inline" width="40"></img> then "Add to Home Screen"',
    showCloseButton: false,
    customClass: {
      confirmButton: 'pwa-confirm-btn'
    }
  })
}

function showPwaInstallBanner() {
  Swal.fire({
    imageUrl: '/icons/apple-touch-icon-192x192.png',
    imageHeight: 100,
    title: 'Install the App',
    html:
    'Install this application ' +
    'for quick and easy access ' +
    'when you are on the go' + 
    '<br/><br/>' +
    '<a class="pwa-install-btn" href="#" onClick="pwaInstallEvent()"><img class="inline" src="/images/download.svg" width="50"></a>',
    showCloseButton: true,
    showConfirmButton: false,
    customClass: {
      confirmButton: 'pwa-confirm-btn'
    }
  })
}

function addInstallEvent() {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();

    // Stash the event so it can be triggered later.
    deferredPrompt = e;

    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);

    if(!isRunningStandalone()){
      showPwaInstallBanner()
    }
  });

  window.addEventListener('appinstalled', () => {
    deferredPrompt = null;
    console.log('PWA was installed');
  });

  $(document).on('click', '.pwa-install-btn', function(e){
    e.preventDefault()
    pwaInstallEvent()
  })
}

function pwaInstallEvent() {
  deferredPrompt.prompt();

  deferredPrompt.userChoice
    .then((choiceResult) => {
      console.log(`User response to the install prompt: ${choiceResult.outcome}`);
      Swal.close()
    })
  
  // We've used the prompt, and can't use it again, throw it away
  deferredPrompt = null;
}

$(document).on('turbolinks:load', function(){
  if($('#pwa-installer').length) {
    if(needsToSeePromptIOS()) {
      showPwaInstallPopupIOS()
    } else {
      addInstallEvent()
    }
  }

  if(isRunningStandalone()) {
    Cookies.set('app-mode', 'standalone')
  } else {
    Cookies.remove('app-mode')
  }
})
